import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useStore } from '../../store';
import { useGqlMutation, useGqlQuery } from '../useGqlQuery';
import {
  GET_WEEKLY_TIMELOGS,
  DISTRIBUTE_TIMELOGS,
  CREATE_TIMELOG,
  UPDATE_TIMELOG,
  DELETE_TIMELOG,
} from './gqlTimelogs';
import Time from '../../utils/time';
import { connect } from '../../utils/helper';
import { GET_REMOTE_TIMELOGS } from '../users/gqlUsers';

export const useRemoteTimelogsQuery = (variables = {}, config = {}) => {
  const { isLoading, isError, isFetching, data } = useGqlQuery(
    variables?.date ? ['remoteTimelogs', variables.date] : ['remoteTimelogs'],
    GET_REMOTE_TIMELOGS,
    variables,
    {
      select: useCallback(({ me: { timelogs } }) => {
        // Add necessary fields to timelogs object from server
        const modifiedRemoteTimelogs = timelogs.map((remoteTimelog) => ({
          ...remoteTimelog,
          ...Time.getTimeFromMinutes(remoteTimelog.time),
          isRemote: true,
        }));
        return modifiedRemoteTimelogs;
      }, []),
      refetchOnWindowFocus: false,
      ...config,
    },
  );

  const timelogs = data ?? [];
  return { isLoading, isError, isFetching, timelogs };
};

export const useWeeklyTimelogs = (variables = {}, config = {}) => {
  const { isLoading, isError, data } = useGqlQuery(
    variables?.date ? ['timelogsWeekly', variables.date] : ['timelogsWeekly'],
    GET_WEEKLY_TIMELOGS,
    variables,
    config,
  );

  const timelogs = data?.timelogsWeek?.data ?? [];
  return { isLoading, isError, timelogs };
};

export const useCreateTimelog = () => {
  const queryClient = useQueryClient();
  const { date } = useStore((state) => ({
    date: state.getFormattedDate(),
  }));

  const mutation = useGqlMutation(['createTimelog'], CREATE_TIMELOG, {
    onSuccess: ({ createTimelog }) => {
      queryClient.setQueryData(['remoteTimelogs', date], ({ me }) => {
        const updatedTimelogs = [createTimelog, ...me.timelogs];
        return {
          me: {
            timelogs: updatedTimelogs,
          },
        };
      });
    },
    toast: {
      successMessage: 'Timelog aangemaakt',
    },
  });

  const createTimelog = (data, options = {}) => {
    const { task, timelog } = data;
    const { timer, commits, ...input } = timelog;

    const time = Time.getMinutesFromTime({
      hours: parseInt(timer.hours, 10),
      minutes: parseInt(timer.minutes, 10),
      seconds: parseInt(timer.seconds, 10),
    });

    return mutation.mutate(
      {
        input: {
          date,
          time,
          ...input,
          task: connect(task.id),
        },
      },
      options,
    );
  };

  return createTimelog;
};

export const useUpdateTimelog = (options) => {
  const queryClient = useQueryClient();

  const { storedTimelog, date } = useStore((state) => ({
    storedTimelog: state.timelog,
    date: state.getFormattedDate(),
  }));

  const mutation = useGqlMutation(['updateTimelog'], UPDATE_TIMELOG, {
    onSuccess: ({ updateTimelog }) => {
      // Update timelog query cache
      queryClient.setQueryData(['remoteTimelogs', date], ({ me }) => {
        const previousTimelogs = me.timelogs;
        const updatedTimelogs = previousTimelogs.map((timelog) =>
          timelog.id === updateTimelog.id ? updateTimelog : timelog,
        );

        return {
          me: {
            timelogs: updatedTimelogs,
          },
        };
      });
    },
    toast: {
      successMessage: 'Timelog geüpdatet',
      hide: options?.hideToast ?? false,
    },
  });

  const updateTimelog = (data, options = {}) => {
    const { task, timelog } = data;
    const { timer, commits, ...input } = timelog;
    const { id } = storedTimelog;

    mutation.mutate(
      {
        input: {
          id,
          date,
          time:
            timelog?.time ??
            Time.getMinutesFromTime({
              hours: parseInt(timer?.hours || 0, 10),
              minutes: parseInt(timer?.minutes || 0, 10),
              seconds: parseInt(timer?.seconds || 0, 10),
            }),
          time_not_billable:
            timer?.nonBillable || timer?.nonBillableHours || timer?.nonBillableMin
              ? Time.getMinutesFromTime({
                  hours: parseInt(Number(timer?.nonBillableHours) || 0, 10),
                  minutes: parseInt(Number(timer?.nonBillableMin) || 0, 10),
                  seconds: parseInt(Number(timer?.nonBillableSec) || 0, 10),
                })
              : 0,
          ...input,
          task: connect(task.id),
        },
      },
      options,
    );
  };

  return updateTimelog;
};

export const useDeleteTimelog = () =>
  useGqlMutation(['deleteTimelog'], DELETE_TIMELOG, {
    toast: {
      successMessage: 'Timelog verwijderd',
      errorMessage: 'Er was een probleem bij het verwijderen van je timelog',
    },
  });

export const useDistributeTimelogs = () =>
  useGqlMutation(['distributeTimelog'], DISTRIBUTE_TIMELOGS, {
    toast: {
      successMessage: 'Tijd succesvol verdeeld',
      errorMessage: 'Opgelet deze tijdsverdeling kan maar 1x per dag worden toegepast',
    },
  });
