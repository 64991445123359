import { gql } from 'graphql-request';

export const FRAGMENT_TIMELOG = gql`
  fragment Timelog on Timelog {
    id
    date
    time
    time_not_billable
    comments
    is_planning
    user(includeArchived: true) {
      first_name
    }
  }
`;

export const FRAGMENT_TIMELOG_TASK = gql`
  fragment TimelogTask on Task {
    id
    name
    budget_time
    progress
    editable
    archived
    is_hours_bundle
    client {
      id
      name
      hours_bundle
      hours_bundle_used
    }
    project {
      id
      project_type_id
      name
    }
  }
`;

export const GET_TIMELOGS = gql`
  query GetTimelogs($first: Int = 10, $page: Int) {
    timelogs(first: $first, page: $page) {
      data {
        ...Timelog
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
  ${FRAGMENT_TIMELOG}
`;

export const CREATE_TIMELOG = gql`
  mutation createTimelog($input: CreateTimelogInput!) {
    createTimelog(input: $input) {
      ...Timelog
      task {
        ...TimelogTask
      }
    }
  }
  ${FRAGMENT_TIMELOG}
  ${FRAGMENT_TIMELOG_TASK}
`;

export const UPDATE_TIMELOG = gql`
  mutation updateTimelog($input: UpdateTimelogInput!) {
    updateTimelog(input: $input) {
      ...Timelog
      task {
        ...TimelogTask
      }
    }
  }
  ${FRAGMENT_TIMELOG}
  ${FRAGMENT_TIMELOG_TASK}
`;

export const DELETE_TIMELOG = gql`
  mutation deleteTimelog($input: DeleteTimelogInput!) {
    deleteTimelog(input: $input)
  }
`;

export const GET_WEEKLY_TIMELOGS = gql`
  query GetWeeklyTimelogs($date: Date, $subWeeks: Int) {
    timelogsWeek(date: $date, subWeeks: $subWeeks) {
      data {
        date
        totalTime: total_time
      }
    }
  }
`;

export const DISTRIBUTE_TIMELOGS = gql`
  mutation distributeTimelog($input: DistributeTimelogInput!) {
    distributeTimelog(input: $input)
  }
`;
