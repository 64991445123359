import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import { Center, Spinner, Textarea, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import ControlledSelect from '../select/ControlledSelect';
import NonBillableTimer from '../time/NonBillableTimer';
import CommitList from '../commit-list/CommitList';
import ClientSearch from '../select/ClientSearch';
import RecentTasks from '../recent-tasks/RecentTasks';

import { useStore } from '../../store';
import { useFormattedSelectOption } from '../../hooks/useFormattedSelectOption';

import { useClientTasks } from '../../api/clients';
import { useMeQuery } from '../../api/users';

import { CLIENT_ID_ESIGN } from '../../constants/enums';
import { usePlanning } from '../../hooks/usePlanning';

export default function TimeTrackingForm() {
  const { createCreatableFormattedOption } = useFormattedSelectOption();
  const { task: storedTask } = useStore((state) => ({
    task: state.task,
  }));

  const { findTaskInPlanning } = usePlanning();

  const {
    control,
    register,
    reset,
    formState: { errors },
  } = useFormContext();

  const subscribedClient = useWatch({
    control,
    name: 'client',
  });

  const selectedClient = subscribedClient?.id || storedTask?.client?.id;

  const { tasks, isLoading: isLoadingTasks } = useClientTasks(selectedClient, {
    enabled: !!selectedClient,
    select: ({ client: { projects, tasks } }) =>
      createCreatableFormattedOption([...tasks, ...projects], {
        isCreatable: parseFloat(selectedClient) !== CLIENT_ID_ESIGN,
      }),
  });

  const { me, isLoading } = useMeQuery();

  useEffect(() => {
    if (!storedTask) return;

    // Populate selected client & task dropdowns (+ task detail name) based on updated stored task
    const { id, name, isNew, project, client } = storedTask;

    if (id) {
      reset({ task: { id, name, isNew, project }, client });
    } else if (client) {
      reset({ client });
    }
  }, [storedTask]);

  if (isLoading) {
    return (
      <Center>
        <Spinner color="blue.500" />
      </Center>
    );
  }

  return (
    <VStack height="full" spacing={4} align="end">
      <ClientSearch control={control} isDisabled={findTaskInPlanning(storedTask?.id)} />

      {selectedClient ? (
        <>
          <ControlledSelect
            control={control}
            id="task"
            name="task"
            placeholder="Selecteer of creëer taak"
            isLoading={isLoadingTasks}
            data={tasks}
            isDisabled={findTaskInPlanning(storedTask?.id)}
          />
          <Textarea
            {...register('timelog.comments', { required: true })}
            placeholder="Comments"
            isInvalid={errors?.timelog?.comments?.type === 'required'}
            flexGrow={1}
            maxH="xs"
            resize="none"
          />

          <NonBillableTimer />
          {me?.github_name && <CommitList name="timelog.commits" />}
        </>
      ) : (
        <RecentTasks control={control} recentTasks={me?.recent_tasks} />
      )}
    </VStack>
  );
}

TimeTrackingForm.propTypes = {
  register: Proptypes.func,
  control: Proptypes.object,
  reset: Proptypes.func,
  onFormSubmit: Proptypes.func,
};
