import React, { useState, useEffect } from 'react';
import { Tooltip, HStack, Text, Box, Checkbox } from '@chakra-ui/react';
import { FiHelpCircle } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { useStore } from '../../store';
import Digit from './Digit';
import Time from '../../utils/time';

const TOOLTIP_PROPS = {
  label: 'Deze uren zitten inbegrepen in bovenstaande tijd.',
  placement: 'top-start',
  hasArrow: true,
  p: '3',
  color: 'blue.500',
  backgroundColor: 'gray.100',
  borderRadius: 'md',
  shadow: 'none',
};

export default function NonBillableTimer() {
  const { setValue } = useFormContext();
  const { timelog, updateLocalTimelog } = useStore((state) => ({
    timelog: state.timelog,
    updateLocalTimelog: state.updateTimelog,
  }));

  const [isNonBillable, setIsNonBillable] = useState(false);
  const [hours, setHours] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const setLocalTimelog = (field, value) => {
    const timeValues = {
      hours: parseInt(hours, 10),
      minutes: parseInt(min, 10),
      seconds: parseInt(sec, 10),
    };

    if (field === 'Hours') {
      timeValues.hours = parseInt(value ?? 0, 10);
    } else if (field === 'Min') {
      timeValues.minutes = parseInt(value ?? 0, 10);
    } else if (field === 'Sec') {
      timeValues.seconds = parseInt(value ?? 0, 10);
    }

    const minutes = Time.getMinutesFromTime(timeValues);

    updateLocalTimelog({
      timelog,
      data: {
        time_not_billable: isNonBillable ? minutes : 0,
        timer: {
          ...timelog?.timer,
          nonBillable: isNonBillable,
          nonBillableHours: timeValues.hours,
          nonBillableMin: timeValues.minutes,
          nonBillableSec: timeValues.seconds,
        },
      },
    });
  };

  useEffect(() => {
    if (!timelog) {
      setIsNonBillable(false);
      setHours(0);
      setMin(0);
      setSec(0);
      setValue('timelog.timer.nonBillable', false);
      return;
    }

    // Get current non-billable state from form
    const currentNonBillable = timelog.timer?.nonBillable;
    const currentNonBillableTime = timelog.time_not_billable;

    if (timelog.isRemote) {
      const hasNonBillableTime = currentNonBillableTime > 0;

      // Preserve non-billable state if it exists
      setIsNonBillable(currentNonBillable || hasNonBillableTime);
      setValue('timelog.timer.nonBillable', currentNonBillable || hasNonBillableTime);

      if (hasNonBillableTime) {
        const time = Time.getTimeFromMinutes(currentNonBillableTime);
        setHours(time.hours);
        setMin(time.minutes);
        setSec(time.seconds || 0);
      }
    } else {
      const nonBillableTime = currentNonBillableTime || 0;
      const isNonBillableTimer = currentNonBillable || false;

      setIsNonBillable(isNonBillableTimer);
      setValue('timelog.timer.nonBillable', isNonBillableTimer);

      if (nonBillableTime > 0) {
        const time = Time.getTimeFromMinutes(nonBillableTime);
        setHours(time.hours);
        setMin(time.minutes);
        setSec(time.seconds || 0);
      }
    }
  }, [timelog?.id, setValue]);

  const handleTimeChange = (field, value) => {
    if (field === 'Hours') {
      setHours(value);
    } else if (field === 'Min') {
      setMin(value);
    } else if (field === 'Sec') {
      setSec(value);
    }

    if (!timelog?.isRemote) {
      setLocalTimelog(field, value);
    }
  };

  const handleCheckboxChange = () => {
    const newIsNonBillable = !isNonBillable;
    setIsNonBillable(newIsNonBillable);
    setValue('timelog.timer.nonBillable', newIsNonBillable);

    if (!newIsNonBillable) {
      setHours(0);
      setMin(0);
      setSec(0);
    }

    if (!timelog?.isRemote) {
      setLocalTimelog(null, null);
    }
  };

  return (
    <>
      <HStack>
        <Checkbox isChecked={isNonBillable} onChange={handleCheckboxChange} size="md">
          Verloren / Non-billable uren
        </Checkbox>
        <Tooltip {...TOOLTIP_PROPS}>
          <span>
            <FiHelpCircle />
          </span>
        </Tooltip>
      </HStack>
      {isNonBillable && (
        <Box w="50%">
          <HStack>
            <Digit
              value={hours}
              onBlur={(value) => handleTimeChange('Hours', value)}
              name="nonBillableHours"
              fontSize="1.5vw"
            />
            <Text fontSize="1.5vw">:</Text>
            <Digit
              value={min}
              onBlur={(value) => handleTimeChange('Min', value)}
              name="nonBillableMin"
              min="0"
              max="59"
              fontSize="1.5vw"
            />
            <Text fontSize="1.5vw">:</Text>
            <Digit
              value={sec}
              onBlur={(value) => handleTimeChange('Sec', value)}
              name="nonBillableSec"
              min="0"
              max="59"
              fontSize="1.5vw"
            />
          </HStack>
        </Box>
      )}
    </>
  );
}
